import { FunctionComponent, useMemo, useState } from 'react';
import { PageSection } from '@utils';

import { SectionSearchSnippet } from '@shares/search-snippet';

import { trackFAQToggle, FAQPanelState } from './tracking';

import './style.scss';
export interface FAQProps {
  panels: {
    title: string;
    content: string;
    show_google_faq: boolean;
  }[];
  prefix: string;
  sectionName: PageSection;
  cityName?: string;
  header?: string;
  className?: string;
  searchSnippet?: SearchSnippetOBJ;
  isMobile?: boolean;
}
export const FAQ: FunctionComponent<FAQProps> = (props: FAQProps) => {
  const [open, setOpen] = useState<number[]>([]);
  const { panels, cityName, header, searchSnippet, isMobile } = props;

  const toggle = (index: number, elem: string) => {
    let newOpen: number[] = [];
    if (open.includes(index)) {
      newOpen = open.filter((i) => i !== index);
      trackFAQToggle(FAQPanelState.Hide, elem, props.sectionName);
    } else {
      newOpen = [...open, index];
      trackFAQToggle(FAQPanelState.Expand, elem, props.sectionName);
    }
    setOpen(newOpen);
  };

  const googleFaqConfig = (show_google_faq: boolean) => {
    const googleConfig = {
      itemScope: 'itemScope',
      itemProp: 'mainEntity',
      itemType: 'https://schema.org/Question'
    };
    return show_google_faq ? googleConfig : {};
  };

  const googleFaqHeader = () => {
    return {
      itemScope: true,
      itemType: 'https://schema.org/FAQPage'
    };
  };

  const setionTitle = useMemo(() => {
    return cityName ? `เช่ารถ${cityName}ราคาถูก` : header ? header : 'คำถามและข้อสงสัย';
  }, [cityName, header]);

  return (
    <div className={`faq faq--modify ${props.className ?? ''}`}>
      <div className="container" {...googleFaqHeader()}>
        {!searchSnippet && <h2 className="pb-3">{setionTitle}</h2>}
        {searchSnippet && (
          <SectionSearchSnippet title={setionTitle} searchSnippet={searchSnippet} isMobile={isMobile} />
        )}
        <div className="accordion" id="accordionFAQ">
          {panels.map((el, i) => {
            return (
              <div className="card" key={i} {...googleFaqConfig(el.show_google_faq)}>
                <div className="card-header" id="headingOne">
                  <button
                    className={`btn btn-link btn--faq ${open.includes(i) ? '' : 'collapsed'}`}
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    onClick={() => toggle(i, el.title)}
                    dangerouslySetInnerHTML={{ __html: el.title }}
                  />
                </div>

                <div
                  id="collapseOne"
                  className={`collapse ${open.includes(i) ? 'show' : ''}`}
                  aria-labelledby="headingOne"
                  data-parent="#accordionFAQ"
                >
                  <div className="card-body" dangerouslySetInnerHTML={{ __html: el.content }} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
