import { sendToDataLayer, getDefaultEventCategory, canUseDOM, PageSection } from '@utils';

export enum FAQPanelState {
  Expand = 'expand',
  Hide = 'hide'
}
const getFAQTitle = (elem: string) => {
  let element = undefined;
  if (canUseDOM()) {
    element = document.createElement('div');
    element.innerHTML = elem;
  }
  return element ? element.innerText : 'undefined';
};
export const trackFAQToggle = (state: FAQPanelState, elem: string, sectionName: PageSection) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(sectionName),
    event_action: state,
    event_label: sectionName === PageSection.CarWithDriver ? `faqs_${getFAQTitle(elem)}` : getFAQTitle(elem)
  });
};
