import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

import './style.scss';
interface SectionSearchSnippetProps {
  searchSnippet: SearchSnippetOBJ;
  title?: string;
  isMobile?: boolean;
}
export const SectionSearchSnippet: FunctionComponent<SectionSearchSnippetProps> = ({ searchSnippet, title }) => {
  const { price_per_day, car, car_brand, insurance, cancellation_policies } = searchSnippet;

  const schemaObj = {
    '@context': 'https://schema.org',
    '@type': 'Dataset',
    name: 'ข้อมูลการเช่ารถ',
    description: 'ข้อมูลเกี่ยวกับราคาเช่ารถเริ่มต้น, รถเช่ายอดนิยม, ประกันรถยนต์, และข้อกำหนดในการยกเลิก',
    about: {
      '@type': 'ItemList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@type': 'Offer',
            price: `${price_per_day}`,
            priceCurrency: 'THB',
            eligibleDuration: {
              '@type': 'QuantitativeValue',
              value: '1',
              unitCode: 'DAY'
            }
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@type': 'Product',
            name: `${car}`,
            brand: `${car_brand}`,
            aggregateRating: {
              '@type': 'AggregateRating',
              reviewCount: '94',
              bestRating: '5',
              ratingValue: '4.5',
              worstRating: '3'
            },
            offers: {
              '@type': 'AggregateOffer',
              offerCount: 1,
              lowPrice: `${price_per_day}`,
              highPrice: `${price_per_day}`,
              priceCurrency: 'THB'
            }
          }
        },
        {
          '@type': 'ListItem',
          position: 3,
          item: {
            '@type': 'Offer',
            name: 'ประกันรถยนต์',
            description: `${insurance}`
          }
        },
        {
          '@type': 'ListItem',
          position: 4,
          item: {
            '@type': 'Reservation',
            name: 'การยกเลิก',
            description: `${cancellation_policies}`
          }
        }
      ]
    }
  };

  return (
    <div className="search-snippet">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaObj)}</script>
      </Helmet>
      {title && <caption className="as-h2 pb-3">{title}</caption>}
      <table className="search-snippet__table">
        <thead>
          <tr className="snippet-header">
            <th scope="col">ราคาเช่ารถเริ่มต้น</th>
            <th scope="col">รถเช่ายอดนิยม</th>
            <th scope="col">ประกันรถยนต์</th>
            <th scope="col">การยกเลิก</th>
          </tr>
        </thead>
        <tbody>
          <tr className="snippet-content">
            <td data-label="ราคาเช่ารถเริ่มต้น">{price_per_day}/วัน</td>
            <td data-label="รถเช่ายอดนิยม">{car}</td>
            <td data-label="ประกันรถยนต์">{insurance}</td>
            <td data-label="การยกเลิก">{cancellation_policies}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default { SectionSearchSnippet };
