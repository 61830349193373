import { FunctionComponent, PureComponent } from 'react';
import { graphql } from 'gatsby';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import Context, { ContextType } from '@ui/components/context';
import { withTrans } from '@shares/locales/hoc';

import SectionSearch from '@shares/search';
import SectionPromotions from '@shares/promotions';
// import SectionCarAvailable from '@shares/car-available';
import SectionValueProps from '@shares/value-props';
import SectionServices from '@shares/services';
import SectionRegions from '@shares/regions';
import SectionFAQ from '@shares/faq';

import { CityPageProps, CityPageState } from './interface';
import { PageSection } from '@utils';
import './style.scss';

const CityPageWrapper: FunctionComponent<CityPageProps> = (props) => (
  <Context>
    <CityPage {...props} />
  </Context>
);

class CityPage extends PureComponent<CityPageProps, CityPageState> {
  static defaultProps = {
    prefix: typeof window === 'object' ? window.localStorage.getItem('i18nextLng') || 'th' : 'th'
  };
  static contextType = ContextType;
  render() {
    const { prefix } = this.props.pageContext;
    const { data, t } = this.props;
    const { city_id, hero, description, keywords, panels, name, header, location_id, rel_canonical, search_snippet } =
      data.markdownRemark.frontmatter;
    const { isMobile } = this.context;
    const backgroundSrc = hero;
    const valueProps = data.siteDescription.edges[0].node.frontmatter.short_value_props;

    return (
      <Layout section={PageSection.City} prefix={prefix}>
        <SEO prefix={prefix} title={header} description={description} keywords={keywords} canonical={rel_canonical} />
        <div>
          <SectionSearch
            backgroundSrc={backgroundSrc}
            valueProps={valueProps}
            cityID={city_id}
            locationID={location_id}
            t={t}
            prefix={prefix}
            sectionName={PageSection.City}
          >
            <div className="heading-text">
              <h1>{`ค้นหารถเช่า${name}ราคาถูกที่สุด`}</h1>
              <h4>{`จากบริษัทเช่ารถ${name}คุณภาพทั่วจังหวัด`}</h4>
            </div>
          </SectionSearch>
          <SectionPromotions cityID={city_id} prefix={prefix} isMobile={isMobile} />
          {/* <SectionCarAvailable
            prefix={prefix}
            cityID={Number(city_id)}
            pickupLocationID={Number(location_id)}
            isCityPage={true}
          /> */}
          <SectionFAQ
            panels={panels}
            cityName={name}
            prefix={prefix}
            sectionName={PageSection.City}
            searchSnippet={search_snippet}
            className="faq--city"
          />
          <SectionValueProps sectionName={PageSection.City} />
          <SectionServices prefix={prefix} sectionName={PageSection.City} />
          <SectionRegions prefix={prefix} sectionName={PageSection.City} />
        </div>
      </Layout>
    );
  }
}

export default withTrans()(CityPageWrapper);

export const query = graphql`
  query ($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      html
      frontmatter {
        name
        path
        header
        sub_title
        description
        keywords
        rel_canonical
        hero {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        location_id
        city_id
        panels {
          title
          content
          show_google_faq
        }
        search_snippet {
          price_per_day
          car
          car_brand
          insurance
          cancellation_policies
        }
      }
    }
    siteDescription: allMarkdownRemark(filter: { fields: { collection: { eq: "site_description" } } }) {
      edges {
        node {
          id
          frontmatter {
            short_value_props {
              content_th
              content_en
            }
          }
        }
      }
    }
  }
`;
